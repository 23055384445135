<template>
  <v-app id="inspire">
    <div class="container-table-informes">
      <div class="container-title-informe">
        <h3><i class="fas fa-ticket-alt pr-2"></i> {{actualizar_Preventa ? 'Actualizar' : 'Crear'}} preventa {{actualizar_Preventa ? '#' + $store.state.preventas.dataPreventaEdit.id : ''}}</h3>
      </div>
      <div class="mt-4 d-flex flex-wrap w-100">
        <div class="col-12 col-md-8 d-flex flex-wrap">
          <div class="col-12 col-md-6">
            <v-select
              :disabled="actualizar_Preventa"
              v-model="id_evento"
              item-value="id"
              item-text="nombre"
              :items="$store.state.informes.eventosInforme"
              label="Evento"
            ></v-select>
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              v-model="nombre"
              label="Nombre preventa"
            ></v-text-field>
          </div>
          <div class="col-12 col-md-6">
            <v-menu transition="scale-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fecha_inicio"
                  label="Fecha inicio"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker class="w-100 picker-fecha" v-model="fecha_inicio" locale="es-ES"></v-date-picker>
            </v-menu>
          </div>
          <div class="col-12 col-md-6">
            <v-menu ref="menu1" transition="scale-transition" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="hora_inicio"
                  label="Hora inicio"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker class="w-100" v-model="hora_inicio" @click:minute="$refs.menu1.save(hora_inicio)"></v-time-picker>
            </v-menu>
          </div>
          <div class="col-12 col-md-6">
            <v-menu transition="scale-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fecha_fin"
                  label="Fecha fin"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker class="w-100 picker-fecha" v-model="fecha_fin" locale="es-ES"></v-date-picker>
            </v-menu>
          </div>
          <div class="col-12 col-md-6">
            <v-menu ref="menu2" transition="scale-transition" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="hora_fin"
                  label="Hora fin"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker class="w-100" v-model="hora_fin" @click:minute="$refs.menu2.save(hora_fin)"></v-time-picker>
            </v-menu>
          </div>
          <div class="col-12 col-md-6">
            <v-select
              v-model="tipo_descuento_servicio"
              item-value="id"
              item-text="nombre"
              :items="[{id: 1, nombre: 'Porcentaje'}, {id: 2, nombre: 'Fijo'}]"
              label="Tipo de descuento servicio:"
            ></v-select>
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              type="number"
              v-model="descuento_servicio"
              :label="tipo_descuento_servicio == 1 ? 'Porcentaje de descuento' : 'Valor de descuento'"
            ></v-text-field>
          </div>
          <div class="col-12 col-md-6">
            <v-select
              v-model="tipo_descuento_precio"
              item-value="id"
              item-text="nombre"
              :items="[{id: 1, nombre: 'Porcentaje'}, {id: 2, nombre: 'Fijo'}]"
              label="Tipo de descuento precio:"
            ></v-select>
          </div>
          <div class="col-12 col-md-6">
            <v-text-field
              type="number"
              v-model="descuento_precio"
              :label="tipo_descuento_precio == 1 ? 'Porcentaje de descuento' : 'Valor de descuento'"
            ></v-text-field>
          </div>
          <div class="col-12 d-flex">
            <div class="col-12 col-md-3">
              <p>Activar la preventa</p>
              <v-switch
                v-model="activo"
                :label="activo ? 'Si' : 'No'"
              ></v-switch>
            </div>
            <div class="col-12 col-md-3">
              <p>Dejar visible en tabla de precios</p>
              <v-switch
                v-model="visible"
                :label="visible ? 'Si' : 'No'"
              ></v-switch>
            </div>
            <div class="col-12 col-md-3">
              <p>Aplica para venta en linea</p>
              <v-switch
                v-model="venta_linea"
                :label="venta_linea ? 'Si' : 'No'"
              ></v-switch>
            </div>
            <div class="col-12 col-md-3">
              <p>Automatica</p>
              <v-switch
                v-model="automatica"
                :label="automatica ? 'Si' : 'No'"
              ></v-switch>
            </div>
          </div>
          <div class="col-12">
            <p class="mb-0">La preventa aplica a: </p>
            <v-radio-group v-model="tipo" class="d-flex">
              <v-radio
                :label="'Todo el evento'"
                :value="1"
              ></v-radio>
              <v-radio
                :label="'Solo a tribunas'"
                :value="2"
              ></v-radio>
              <v-radio
                :label="'Solo a localidades'"
                :value="3"
              ></v-radio>
            </v-radio-group>
          </div>
          <div class="col-12 col-md-6" v-if="tipo == 2">
            <v-select
              v-model="ids_tribunas"
              item-value="value"
              item-text="text"
              multiple
              chips
              :items="dataEvento.tribunas"
              no-data-text="Seleccione un evento"
              label="Tribunas que aplican a la preventa"
            ></v-select>
          </div>
          <div class="col-12 col-md-6" v-if="tipo == 3">
            <v-select
              v-model="ids_localidades"
              item-value="value"
              multiple
              chips
              item-text="text"
              no-data-text="Seleccione un evento"
              :items="dataEvento.localidades"
              label="Localidad que aplican a la preventa"
            ></v-select>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <p>Aplica al evento: <b>{{dataEvento.nombre}}</b></p>
          <p>Nombre de la preventa: <b>{{nombre}}</b></p>
          <p>Fecha y hora de inicio: <b>{{fecha_inicio}} / {{hora_inicio}}</b></p>
          <p>Fecha y hora de fin: <b>{{fecha_fin}} / {{hora_fin}}</b></p>
          <p>Descuento servicio: <b :style="tipo_descuento_servicio == 1 && (descuento_servicio > 100 || descuento_servicio < 0) ? 'color: red' : ''">{{tipo_descuento_servicio == 2 ? '$' + new Intl.NumberFormat("de-DE").format(descuento_servicio) : descuento_servicio + '%'}}</b></p>
          <p>Descuento precio: <b :style="tipo_descuento_precio == 1 && (descuento_precio > 100 || descuento_precio < 0) ? 'color: red' : ''">{{tipo_descuento_precio == 2 ? '$' + new Intl.NumberFormat("de-DE").format(descuento_precio) : descuento_precio + '%'}}</b></p>
          <p>Preventa aplica a: <b>{{tipo == 1 ? 'Todo el evento' : tipo == 2 ? 'Solo a tribunas' : 'Solo a localidades'}}</b></p>
          <p>Preventa activa: <b>{{activo ? 'SI' : 'NO'}}</b></p>
          <p>Visible en tablade precios: <b>{{visible ? 'SI' : 'NO'}}</b></p>
          <p>Venta en linea: <b>{{venta_linea ? 'SI' : 'NO'}}</b></p>
          <p v-if="tipo != 1">{{tipo == 2 ? 'Tribunas: ' : 'Localidades: '}} {{tipo == 2 ? ids_tribunas.toString() : ids_localidades.toString()}}</p>
          <div class="mt-3">
            <v-btn @click="validatePreventa()" color="green" title="Agregar preventa" class="text-white"><span>{{actualizar_Preventa ? 'Actualizar' : 'Crear'}} preventa</span><i class="pl-3 fas fa-plus-circle"></i></v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      id_evento: '',
      nombre: '',
      tipo: 1,
      fecha_inicio: null,
      hora_inicio: null,
      fecha_fin: null,
      hora_fin: null,
      tipo_descuento_servicio: 1,
      descuento_servicio: 0,
      tipo_descuento_precio: 1,
      descuento_precio: 0,
      activo: true,
      visible: true,
      venta_linea: true,
      automatica: true,
      ids_tribunas: [],
      ids_localidades: [],
      actualizar_Preventa: false,
    }
  },
  async mounted(){
    await this.$store.dispatch('informes/getEventos');
    if(this.$route.params.id_Preventa_Edit){
      this.actualizar_Preventa = true;
      await this.$store.dispatch('preventas/getPreventaById', this.$route.params.id_Preventa_Edit)
      .then(()=>{
        this.id_evento = this.$store.state.preventas.dataPreventaEdit.id_evento
        this.nombre = this.$store.state.preventas.dataPreventaEdit.nombre
        this.tipo = this.$store.state.preventas.dataPreventaEdit.tipo
        this.fecha_inicio = this.$store.state.preventas.dataPreventaEdit.fecha_inicio
        this.hora_inicio = this.$store.state.preventas.dataPreventaEdit.hora_inicio
        this.fecha_fin = this.$store.state.preventas.dataPreventaEdit.fecha_fin
        this.hora_fin = this.$store.state.preventas.dataPreventaEdit.hora_fin
        this.tipo_descuento_servicio = this.$store.state.preventas.dataPreventaEdit.tipo_descuento_servicio
        this.descuento_servicio = this.$store.state.preventas.dataPreventaEdit.descuento_servicio
        this.tipo_descuento_precio = this.$store.state.preventas.dataPreventaEdit.tipo_descuento_precio
        this.descuento_precio = this.$store.state.preventas.dataPreventaEdit.descuento_precio
        this.activo = this.$store.state.preventas.dataPreventaEdit.activo
        this.visible = this.$store.state.preventas.dataPreventaEdit.visible
        this.venta_linea = this.$store.state.preventas.dataPreventaEdit.venta_linea
        this.ids_tribunas = this.$store.state.preventas.dataPreventaEdit.ids_tribunas
        this.ids_localidades = this.$store.state.preventas.dataPreventaEdit.ids_localidades
        this.automatica = this.$store.state.preventas.dataPreventaEdit.automatica
      });
    }
    document.title = 'Crear preventa - TicketShop'
  },
  computed: {
    dataEvento(){
      return this.id_evento ? this.$store.state.informes.eventosInforme.find((e)=> e.id == this.id_evento) : {nombre: ''}
    }
  },
  methods: {
    validatePreventa(){
      if(this.id_evento && this.nombre && this.fecha_inicio && this.hora_inicio && this.fecha_fin && this.hora_fin && this.descuento_servicio && this.descuento_precio){
        let data = {
          id_evento: this.id_evento,
          nombre: this.nombre,
          fecha_inicio: this.fecha_inicio,
          hora_inicio: this.hora_inicio,
          fecha_fin: this.fecha_fin,
          hora_fin: this.hora_fin,
          tipo_descuento_servicio: this.tipo_descuento_servicio,
          descuento_servicio: this.tipo_descuento_servicio == 1 ? this.descuento_servicio / 100 : this.descuento_servicio,
          tipo_descuento_precio: this.tipo_descuento_precio,
          descuento_precio:  this.tipo_descuento_precio == 1 ? this.descuento_precio / 100 : this.descuento_precio,
          activo: this.activo,
          visible: this.visible,
          venta_linea: this.venta_linea,
          ids_tribunas: this.ids_tribunas,
          ids_localidades: this.ids_localidades,
          tipo: this.tipo,
          automatica: this.automatica
        }
        if(this.actualizar_Preventa){
          data.id_preventa = this.$store.state.preventas.dataPreventaEdit.id;
          this.$store.dispatch('preventas/updatePreventa', data)
        }else{
          this.$store.dispatch('preventas/crearPreventa', data)
        }
      }else{
        swal("Mensaje!", "Todos los campos son requeridos", "warning");
      }
    }
  }
}
</script>
<style>
  .v-date-picker-table{
    height: auto;
  }
  .v-input--radio-group__input{
    flex-direction: row !important;
  }
  .v-input--radio-group__input .v-radio{
    width: 33%;
    margin-right: 8px !important;
  }
  .picker-fecha .v-picker__body{
    width: 100% !important;
    max-width: 450px !important;
  }
  b{
    font-weight: bold;
  }
</style>